@media screen and (max-width: 320px){
  iframe[data-hcaptcha-response], .ske-hcaptcha {
    width: 90% !important;
    border-radius: 0 0.2rem 0.4rem 0;
  };
  iframe[data-hcaptcha-response]{
  	border-right: solid whitesmoke 0.1rem;
  };
}


