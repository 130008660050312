@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Readex+Pro&display=swap');
body {
  font-family: 'Readex Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*external*/
.m-0-auto{
  margin: 0 auto;
}
.m-5{
  margin: 1.25rem;
}
.mb-5{
  margin-bottom: 1.25rem;
}
/*.mx-5{
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}*/
.my-5{
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}
.mt-2{
    margin-top: 0.5rem;
}

.h-72{
  height: 18rem;
}

.ske-banner-image{
  background: hsl(var(--b1) / var(--tw-bg-opacity));
}

.ske-input, .ske-button, .ske-hcaptcha{
  background: hsl(var(--b2) / var(--tw-bg-opacity));
}


@media screen and (min-width: 1026px){
  .ske-banner-image{
    width: 379px;
    height: 288.750px;
  }
}

@media screen and (min-width: 500px) and (max-width: 1026px){
  .ske-banner-image{
    width: 420px;
    height: 320px;
  }
}

@media screen and (min-width: 320px) and (max-width: 500px){
  .ske-banner-image{
    width: 327px;
    height: 249.141px;
  }
}

@media screen and (max-width: 320px){
  .ske-banner-image{
    width: 256px;
    height: 195.047px;
  };
}


iframe[data-hcaptcha-response], .ske-hcaptcha{
  margin: 0 auto;
}

.skeleton{
  pointer-events: none;
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
}
.skeleton--page{
  display: none;
}